module.exports = {
  siteTitle: 'Kenya Premier League', // <title>
  manifestName: 'kenyapremierleague',
  manifestShortName: 'kpl', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#000',
  manifestThemeColor: '#B43130',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-paradigmshift/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Kenya Premier League',
  subHeading: 'Strategic plan 2022 - 2027',
  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/kenyanpremierleague',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/TheOfficialKPL',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/TheOfficialKPL',
    },
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      url: 'https://youtube.com/TheOfficialKPL',
    },
  ],
  emailId: 'info@kenyapremierleague.com',
};
