import React from 'react';
export default function ContactForm() {
  return (
    <>
      <header>
        <h2 style={{ color: '#fff' }}>Get in touch</h2>
      </header>
      <div className="content">
        <p>
          Our goal is to take Kenya Premier League Member Clubs to a greater
          standard of professionalism, corporate governance and financial
          sustainability. <strong>Get in touch</strong> to learn more.
        </p>
        {/* <form>
          <div className="fields">
            <div className="field half">
              <input type="text" name="name" id="name" placeholder="Name" />
            </div>
            <div className="field half">
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            <div className="field">
              <textarea
                name="message"
                id="message"
                placeholder="Message"
                rows="7"
              />
            </div>
          </div>
          <ul className="actions">
            <li>
              <input
                type="submit"
                value="Send Message"
                className="button primary"
              />
            </li>
          </ul>
        </form> */}
      </div>
    </>
  );
}
