import React from 'react';
import logo from '../assets/images/logo.svg';

export default function Navbar() {
  return (
    <nav className="Nav">
      {/* <div className="blue"></div> */}
      <div className="red"></div>
      <div className="Nav--Container">
        <div className="logo">
          <img src={logo} alt="Kenya Premier League" />
        </div>
        <div className="NavText">
          Restructuring / Rebranding / Repositioning the <u>Professional</u>{' '}
          Game
        </div>
      </div>
    </nav>
  );
}
