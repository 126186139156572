import React from 'react';
import Scroll from './Scroll';
import config from '../../config';
import logo from '../assets/images/logo.svg';

export default function Header() {
  return (
    <header>
      {/* <div className="logoContainer">
        <div className="logo">
          <img src={logo} alt="Kenya Premier League" />
        </div>
      </div> */}
      <h1 style={{ color: '#fff', marginTop: '150px' }}>{config.heading}</h1>
      <p style={{ color: '#fff' }}>{config.subHeading}</p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="first">
            <a href="#first" className="arrow">
              <span className="label">Next</span>
            </a>
          </Scroll>
        </li>
      </ul>
    </header>
  );
}
